import { useEffect, useState } from 'react';

// Hook
function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<{
		width: number;
		height: number;
		isMobile?: boolean;
		isTablet?: boolean;
		isDesktop?: boolean;
	}>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
				isMobile: window.innerWidth <= 480,
				isTablet: window.innerWidth <= 768,
				isDesktop: window.innerWidth > 768,
			});
		}

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}

export { useWindowSize };
