import {
	Experience,
	RRSS,
	Header,
	Stack,
	MyServices,
	Footer,
	Posts,
} from './components';
import avatar from './avatar.png';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const Landing = () => (
	<>
		<Header
			name="Juan Soria"
			position="Full Stack Developer"
			profilePic={avatar}
		/>
		<RRSS />
		<Experience />
		<Stack />
		<MyServices />
		<Posts />
		<Footer />
	</>
);

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/*" element={<Landing />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
