import styled from 'styled-components';
import { Subheader } from './Subheader';
import { waves } from '../../assets';
import { Image } from './Image';
import { useWindowSize } from '../../hooks/useWindowSize';

const StyledHeader = styled.div`
	align-self: stretch;
	background-color: var(--purple);
	background-image: url(${waves});
	background-blend-mode: color-dodge;

	width: 100vw;
	min-height: 260px;
	display: flex;
	flex-direction: column;
	background-size: cover;
`;

const StyledTitleBox = styled.div`
	display: flex;
	width: 100%;
	height: 25vh;
	align-items: center;
	justify-content: center;

	margin: 20px 0;

	img {
		margin-right: 100px;
	}

	p {
		color: var(--white);
		margin-top: 85px;
		margin-left: -125px;
		font-weight: bolder;
	}
`;

const StyledTitle = styled.h1`
	font-size: xxx-large;
	padding: 0;
`;

const StyledTitleContainer = styled.span`
	display: flex;
	max-width: 720px;
	align-items: center;

	padding: 20px;
	border-radius: 20px;

	z-index: 9999;

	background: linear-gradient(
		to top right,
		rgba(255, 255, 255, 0.1),
		transparent,
		rgba(255, 255, 255, 0.2),
		transparent,
		rgba(255, 255, 255, 0.1)
	);
`;

interface HeaderProps {
	name: string;
	position: string;
	profilePic?: string;
}

export const Header: React.FC<HeaderProps> = ({
	name,
	position,
	profilePic,
}) => {
	const { width } = useWindowSize();

	const simplerVersion = width <= 750;

	return (
		<StyledHeader>
			<StyledTitleBox>
				<StyledTitleContainer className="box-shadow">
					{simplerVersion ? null : (
						<Image size={0.9} profilePic={profilePic} />
					)}
					<StyledTitle>{name}</StyledTitle>
					<p>{position}</p>
				</StyledTitleContainer>
			</StyledTitleBox>
			{simplerVersion ? null : <Subheader />}
		</StyledHeader>
	);
};
