import styled from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { open } from '../../utils';

const StyledMyServices = styled.span`
	display: flex;
	width: 100%;
	min-height: 100vh;

	justify-content: center;

	background: radial-gradient(
		ellipse at bottom left,
		var(--purple),
		var(--purple-dark-background)
	);
`;

const StyledContent = styled.span`
	display: flex;
	flex-direction: column;
	width: 75%;

	justify-content: center;
	padding-bottom: 32px;
`;

const StyledColumnsBlock = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media (max-width: 1080px) {
		flex-direction: column;
	}
`;

const StyledColumn = styled.span<{ borderLess?: boolean }>`
	display: flex;
	flex-direction: column;

	@media (min-width: 1080px) {
		width: 30%;
		border-right: ${({ borderLess }) =>
			borderLess ? 'none' : 'var(--white) solid 1px'};
	}

	:last-child {
		border-right: none;
	}

	h3,
	p {
		width: 80%;
		margin: 0;
		margin-bottom: 10px;
	}

	padding: 20px 0;
`;

const StyledButton = styled.button`
	align-self: center;
	margin-left: -20%;

	padding: 10px 30px;
	border-radius: 5px;
	border: var(--dark) solid 1px;
	background-color: var(--dark);
	color: var(--white);

	:hover {
		transform: scale(1.1);
		cursor: pointer;
	}

	:active {
		background-color: var(--grey);
	}

	transition: transform 0.4s linear;

	@media (max-width: 1080px) {
		margin-top: 30px;
		align-self: flex-start;
		margin-left: 0;
	}
`;

const StyledIntro = styled.span`
	display: flex;
	flex-direction: column;

	width: 100%;
	max-width: 1080px;

	@media (min-width: 1080px) {
		margin-bottom: 80px;
	}

	p {
		margin-top: 0;
		margin-bottom: 10px;
		width: 93%;
	}
`;

const DesktopColumns: React.FC<{ onClick: () => void }> = ({ onClick }) => (
	<>
		<StyledColumnsBlock>
			<StyledColumn>
				<h3>Development</h3>
				<p>
					As a software engineer I can design and create custom
					solutions for your business.
				</p>
				<p>
					You can hire my services for an entire project or we can
					agree concrete interventions such as providing solutions and
					following up with your team or quality rising interventions.
				</p>
			</StyledColumn>
			<StyledColumn>
				<h3>Trainer</h3>
				<p>
					As a constant learner I'm always looking to be exploring the
					newest technologies and methodologies. You can contact me to
					have a chat on what I can bring to your team in terms of
					techniques, methodologies or technologies.
				</p>
				<p>I'm always willing to share knowledge.</p>
			</StyledColumn>
			<StyledColumn>
				<h3>Mentorship</h3>
				<p>
					Learning to teach has become a key skill in my career. My
					fastest self-development epoch came thanks to receiving
					mentorship from great profesionals. Nowadays I just try to
					give that back to whoever wants it.
				</p>
				<p>
					If you are passionate about software and are a compromised
					person, don't doubt it.
				</p>
			</StyledColumn>
		</StyledColumnsBlock>
		<StyledColumnsBlock>
			<StyledColumn>
				<StyledButton onClick={onClick} className="box-shadow">
					Hire this service
				</StyledButton>
			</StyledColumn>
			<StyledColumn>
				<StyledButton onClick={onClick} className="box-shadow">
					Train my team
				</StyledButton>
			</StyledColumn>
			<StyledColumn>
				<StyledButton onClick={onClick} className="box-shadow">
					Be my mentor
				</StyledButton>
			</StyledColumn>
		</StyledColumnsBlock>
	</>
);

const MobileColumns: React.FC<{ onClick: () => void }> = ({ onClick }) => (
	<StyledColumnsBlock>
		<StyledColumn>
			<h3>Development</h3>
			<p>
				As a software engineer I can design and create custom solutions
				for your business.
			</p>
			<p>
				You can hire my services for an entire project or we can agree
				concrete interventions such as providing solutions and following
				up with your team or quality rising interventions.
			</p>
			<StyledButton onClick={onClick} className="box-shadow">
				Hire this service
			</StyledButton>
		</StyledColumn>
		<StyledColumn>
			<h3>Trainer</h3>
			<p>
				As a constant learner I'm always looking to be exploring the
				newest technologies and methodologies. You can contact me to
				have a chat on what I can bring to your team in terms of
				techniques, methodologies or technologies.
			</p>
			<p>I'm always willing to share knowledge.</p>
			<StyledButton onClick={onClick} className="box-shadow">
				Train my team
			</StyledButton>
		</StyledColumn>
		<StyledColumn>
			<h3>Mentorship</h3>
			<p>
				Learning to teach has become a key skill in my career. My
				fastest self-development epoch came thanks to receiving
				mentorship from great profesionals. Nowadays I just try to give
				that back to whoever wants it.
			</p>
			<p>
				If you are passionate about software and are a compromised
				person, don't doubt it.
			</p>
			<StyledButton onClick={onClick} className="box-shadow">
				Be my mentor
			</StyledButton>
		</StyledColumn>
	</StyledColumnsBlock>
);

const MyServices: React.FC<any> = () => {
	const onClick = () =>
		open(
			'https://es.linkedin.com/in/juan-francisco-soria-ferrer-576468168'
		);

	const { width } = useWindowSize();

	return (
		<StyledMyServices>
			<StyledContent className="max-width">
				<h1>Services</h1>
				<StyledIntro>
					<p>
						I offer my services as a independant professional.
						Whether if you need high quality solutions, learn a new
						methodology, improve your processes or just training
						your team, you can reach out to me.
					</p>
					<p>
						I also offer myself as a mentor for newbies - and not so
						newbies. Contact me and we can define a plan for you!
					</p>
				</StyledIntro>
				{width && width >= 1080 ? (
					<DesktopColumns onClick={onClick} />
				) : (
					<MobileColumns onClick={onClick} />
				)}
			</StyledContent>
		</StyledMyServices>
	);
};

export { MyServices };
