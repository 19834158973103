import { useState } from 'react';
import styled from 'styled-components';
import { Perk } from './Perk';
import {
	faBookOpen,
	faBookOpenReader,
	faBriefcaseClock,
	faComments,
	faPersonChalkboard,
	faHeart,
	faChevronUp,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSubheader = styled.span<{ expanded?: boolean }>`
	height: ${({ expanded }) => (expanded ? '250px' : '100px')};
	width: 100%;

	@media (min-height: 1150px) {
		background: linear-gradient(
			to bottom,
			transparent,
			rgba(255, 255, 255, 0.2),
			rgba(255, 255, 255, 0.3),
			rgba(255, 255, 255, 0.4)
		);
	}

	display: flex;
	flex-direction: row;
	justify-content: center;

	transition: height 0.5s linear;

	p {
		color: white;
		font-weight: bolder;
	}

	${({ expanded }) =>
		expanded
			? `
		animation: shaded 100s infinite !important;
		background-color: rgba(255, 255, 255, 0.05) !important;
	`
			: ''}

	:hover {
		animation: shaded 100s infinite;
		background-color: rgba(255, 255, 255, 0.05);
	}

	.icon {
		width: 25px;
		height: 25px;
		align-self: flex-start;
		margin-top: 30px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		color: var(--white);
		blur: 20px;

		border-radius: 20px;

		padding: 5px;

		font-size: 40px;

		:hover {
			cursor: pointer;
			opacity: 1;
		}

		:active {
			animation: spin 0.1s linear;
		}

		transition: opacity 0.5s linear;
		margin-left: 50px;
	}
`;

const StyledAboutMe = styled.span`
	color: black;
	text-align: center;

	max-width: 720px;
	margin-right: -90px;

	overflow: hidden;

	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: flex-start;

	p {
		margin-top: 20px;
	}
`;

const StyledPerks = styled.span`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	gap: 10px;

	margin-bottom: 20px;
`;

const StyledPerksRow = styled.span`
	display: flex;
	flex-direction: space-between;
`;

const StyledSubheaderContent = styled.span`
	display: flex;
	justify-content: space-evenly;
	width: 100%;
`;

const Subheader: React.FC = () => {
	const [expanded, setExpanded] = useState(false);

	return (
		<StyledSubheader
			onClick={() => setExpanded((prev) => !prev)}
			expanded={expanded}
		>
			<StyledSubheaderContent className="max-width">
				<br />
				<StyledAboutMe>
					<p>
						Software engineering services for your teams. <br />
						Helping companies to design, deliver and mantain high
						quality software solutions.
					</p>
					{expanded ? (
						<>
							<p>Here you can find a list of my traits.</p>
							<StyledPerks>
								<StyledPerksRow>
									<Perk
										icon={faBriefcaseClock}
										perk="Schedule Hability"
									/>
									<Perk icon={faComments} perk="Easy treat" />
									<Perk
										icon={faBookOpenReader}
										perk="Constant learner"
									/>
								</StyledPerksRow>
								<StyledPerksRow>
									<Perk icon={faBookOpen} perk="Writer" />
									<Perk
										icon={faPersonChalkboard}
										perk="Trainer"
									/>
									<Perk icon={faHeart} perk="Passionate" />
								</StyledPerksRow>
							</StyledPerks>
						</>
					) : null}
				</StyledAboutMe>
				<span className="icon" role="button">
					<FontAwesomeIcon
						icon={expanded ? faChevronUp : faChevronDown}
					/>
				</span>
			</StyledSubheaderContent>
		</StyledSubheader>
	);
};

export { Subheader };
