import styled from 'styled-components';

const StyledLayout = styled.span<{ minHeight?: string }>`
	display: flex;
	flex-direction: column;
	width: 75%;

	${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
`;

const StyledContent = styled.span<{ contentMaxWidth?: string }>`
	max-width: ${({ contentMaxWidth }) => contentMaxWidth ?? '2400px'};
`;

interface LayoutProps {
	title?: string;
	contentMaxWidth?: string;
	minHeight?: string;
	children: any;
}

const Layout: React.FC<LayoutProps> = ({ title, children, ...styleProps }) => {
	return (
		<StyledLayout {...styleProps} className="max-width">
			<h1>{title}</h1>
			<StyledContent {...styleProps}>{children}</StyledContent>
		</StyledLayout>
	);
};

export { Layout };
