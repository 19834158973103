import styled from 'styled-components';
import {
	csharp,
	typescript,
	nestjs,
	react,
	dotnet,
	js,
	aws,
	azure,
} from './icons';
import { Layout } from '../Layout';

const StyledIconsBlock = styled.span`
	display: flex;
	flex-direction: column;

	gap: 30px;

	h3 {
		margin-bottom: -10px;
		margin-top: 0;
	}

	width: 100%;
	max-width: 1080px;
`;

const StyledStackBlock = styled.span`
	display: flex;
	flex-direction: row;
	align-items: center;

	gap: 20px;

	@media (max-width: 1080px) {
		flex-direction: column;
	}
`;

const StyledIconRow = styled.span`
	gap: 10px;
	display: flex;
	flex-direction: row;

	align-items: center;

	p {
		font-weight: bolder;
	}
`;

const StyledIcon = styled.img<{ circle?: boolean }>`
	height: 40px;
	width: 40px;
	${({ circle }) => (circle ? 'border-radius: 2000px' : '')};
`;

const StyledIconContainer = styled.span`
	display: flex;
	flex-direction: column;

	width: 100%;

	border: rgba(255, 255, 255, 0.4) solid 2px;
	padding: 20px 0;
	border-radius: 5px;

	align-items: center;
	justify-content: center;
	gap: 10px;

	:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
`;

const StyledLevelContainer = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 20px;

	gap: 5px;
`;

const StyledLevelSquare = styled.span<{ filled?: boolean }>`
	display: flex;

	border: rgba(255, 255, 255, 0.75) solid 2px;
	border-radius: 5px;

	background-color: ${({ filled }) =>
		filled ? 'rgba(255, 255, 255, 0.5)' : 'none'};

	width: 10px;
	height: 10px;
`;

interface IconProps {
	asset: any;
	text: string;
	level: number;
	circle?: boolean;
}

const maxLevel = 5;

const Icon: React.FC<IconProps> = ({ asset, text, circle, level }) => (
	<StyledIconContainer>
		<StyledIconRow>
			<StyledIcon circle={circle} src={asset} alt="asset" />
			<p>{text}</p>
		</StyledIconRow>
		<StyledLevelContainer>
			{[...Array(maxLevel)].map((_, index) => (
				<StyledLevelSquare key={index} filled={index < level} />
			))}
		</StyledLevelContainer>
	</StyledIconContainer>
);

export const Stack = () => {
	return (
		<Layout minHeight="70vh" title="Stack">
			<StyledIconsBlock>
				<h3>Languages</h3>
				<StyledStackBlock>
					<Icon level={5} asset={csharp} text="C Sharp" />
					<Icon level={5} asset={typescript} text="Typescript" />
					<Icon level={4} asset={js} text="Javascript" />
				</StyledStackBlock>
				<h3>Libs / Frameworks</h3>
				<StyledStackBlock>
					<Icon level={5} asset={dotnet} circle text="DotNet" />
					<Icon level={5} asset={react} text="React" />
					<Icon level={3} asset={nestjs} circle text="NestJS" />
				</StyledStackBlock>
				<h3>Cloud</h3>
				<StyledStackBlock>
					<Icon
						level={3}
						asset={aws}
						circle
						text="Amazon Web Services"
					/>
					<Icon
						level={3}
						asset={azure}
						circle
						text="Microsoft Azure"
					/>
				</StyledStackBlock>
			</StyledIconsBlock>
		</Layout>
	);
};
