import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const StyledPerk = styled.span`
	display: flex;
	width: 18em;
	height: 1em;
	padding: 0.5em 1em;
	align-items: center;

	width: 100%;

	gap: 20px;

	p {
		font-weight: bolder;
		width: max-content !important;
	}
`;

const StyledIcon = styled(FontAwesomeIcon)``;

interface PerkProps {
	perk: string;
	icon?: IconDefinition;
}

export const Perk = (props: PerkProps) => {
	const { icon, perk } = props;
	return (
		<StyledPerk>
			{icon && <StyledIcon color="white" icon={icon} size="lg" />}
			<p>{perk}</p>
		</StyledPerk>
	);
};
