import styled from 'styled-components';
import { contact } from '../../assets';
import { useWindowSize } from '../../hooks/useWindowSize';
import { open } from '../../utils';

const StyledFooter = styled.span`
	width: 100%;
	display: flex;
	flex-direction: column;

	align-items: center;

	background-color: var(--purple);

	min-height: 250px;
`;

const StyledColumnsBlock = styled.span`
	width: 75%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	img {
		@media (max-width: 2160px) {
			visibility: hidden;
		}
		height: 150px;
		width: 300px;
		padding: 50px 0;
	}
`;

const StyledButton = styled.button`
	padding: 10px 30px;
	border-radius: 5px;
	border: var(--dark) solid 1px;
	background-color: var(--dark);
	color: var(--white);

	:hover {
		transform: scale(1.1);
		cursor: pointer;
	}

	:active {
		background-color: var(--grey);
	}

	@media (max-width: 810px) {
		margin-top: 25px;
	}

	transition: transform 0.4s linear;
`;

const StyledContactMe = styled.span`
	padding: 20px 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 25%;

	text-align: right;

	h3,
	p {
		margin: 0;
	}

	button {
		align-self: flex-end;
		margin-top: 20px;
	}
`;

const StyledLinks = styled.span`
	display: flex;
	flex-direction: column;

	.info-text {
		align-self: flex-end;
		margin-top: 25px;
		font-style: italic;
	}

	.option {
		padding-left: 25px;
		border-left: white solid 2px;
		font-weight: bolder;

		:hover {
			opacity: 1;
			cursor: pointer;
		}

		transition: opacity 0.5s linear;
	}

	p {
		margin: 0;
		opacity: 0.66;

		padding-bottom: 5px;

		strong {
			display: flex;
			font-weight: lighter;
			border-bottom: rgba(255, 255, 255, 0.75) solid 2px;
			height: 19.5px;
			width: max-content;

			padding-right: 5px;

			opacity: 1;
		}
	}
`;

const Footer: React.FC<any> = () => {
	const onClick = () =>
		open(
			'https://es.linkedin.com/in/juan-francisco-soria-ferrer-576468168'
		);

	const { width } = useWindowSize();

	if (width && width <= 810) {
		return (
			<StyledFooter>
				<StyledColumnsBlock className="max-width">
					<StyledLinks>
						<p className="info-text">
							This portfolio has been made by Juan Soria. <br />
							Juan Soria,
							<strong>Software to empower people</strong>
						</p>
						<StyledButton className="box-shadow" onClick={onClick}>
							Contact
						</StyledButton>
					</StyledLinks>
				</StyledColumnsBlock>
			</StyledFooter>
		);
	}

	return (
		<StyledFooter>
			<StyledColumnsBlock className="max-width">
				<StyledLinks>
					<h3>Services</h3>
					<p className="option" onClick={onClick}>
						Development
					</p>
					<p className="option" onClick={onClick}>
						Trainer
					</p>
					<p className="option" onClick={onClick}>
						Mentorship
					</p>
					<p className="info-text">
						This portfolio has been made by Juan Soria. <br />
						Juan Soria, <strong>Software to empower people</strong>
					</p>
				</StyledLinks>
				<img src={contact} alt="contact" />
				<StyledContactMe>
					<h3>Contact me!</h3>
					<p>
						Interested? You can reach me out following this button!
					</p>
					<StyledButton className="box-shadow" onClick={onClick}>
						Contact
					</StyledButton>
				</StyledContactMe>
			</StyledColumnsBlock>
		</StyledFooter>
	);
};

export { Footer };
