import { useState } from 'react';
import styled from 'styled-components';
import { Layout } from '../Layout';
import { useWindowSize } from '../../hooks/useWindowSize';
import { profile } from '../../assets';

const StyledAssetContainer = styled.span`
	position: absolute;
	top: 35vh;
	right: 0;
	opacity: 1;

	@media (min-width: 3200px) {
		right: 15vw;
	}

	@media (min-width: 2040px) {
		opacity: 0.01;
	}

	@media (min-width: 2080px) {
		opacity: 0.6;
	}

	@media (min-width: 2140px) {
		opacity: 0.8;
	}

	transition: right 0.5s linear, opacity 1s linear;

	img {
		transform: scale(0.6);
		border-bottom: white solid 2px;
		padding: 0 100px;
	}

	background: radial-gradient(
		var(--purple),
		var(--purple-background),
		transparent 65%
	);

	animation: shutDown 1s linear;

	:hover {
		animation: enlight 1s linear;
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 80%
		);
	}

	border-radius: 20px;
`;

const StyledExperienceCardGrid = styled.span`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const StyledExperienceCardRow = styled.span`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 20px;

	@media (min-width: 1100px) {
		flex-direction: row;
		width: 1080px;
		min-width: 1080px;
	}
`;

const StyledExperienceCard = styled.span<{ expanded?: boolean }>`
	display: flex;
	flex-direction: column;

	@media (min-width: 1100px) {
		min-width: 300px;
		width: 30%;
	}

	background-color: var(--dark);

	z-index: 99999;

	height: ${({ expanded }) => (expanded ? '200px' : '25px')};
	overflow: hidden;

	transition: height 1s linear;

	:focus {
		background-color: rgba(255, 255, 255, 0.2);
	}

	:hover {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.1);
	}

	h4 {
		margin: 0;
	}

	p {
		width: 90%;
	}

	border: rgba(255, 255, 255, 0.4) solid 2px;
	border-radius: 5px;
	padding: 10px;
`;

const StyledExperienceContent = styled.span`
	display: flex;
	flex-direction: row;
`;

interface CardProps {
	company: string;
	description: string;
	position: string;
	startExpanded?: boolean;
}

const ExperienceCard: React.FC<CardProps> = ({
	company,
	position,
	description,
	startExpanded,
}) => {
	const [expanded, setExpanded] = useState(startExpanded);
	return (
		<StyledExperienceCard
			expanded={expanded}
			onClick={() => setExpanded((prev) => !prev)}
		>
			<h4>
				{company} - {position}
			</h4>
			<p>{description}</p>
		</StyledExperienceCard>
	);
};

const Experience = () => {
	const { width } = useWindowSize();

	return (
		<Layout minHeight="30vh" title="Experience">
			<StyledExperienceContent>
				<StyledExperienceCardGrid>
					<StyledExperienceCardRow>
						<ExperienceCard
							company="Everis"
							position="Architecture Intern"
							description="As an intern I got assigned the responsibility of delivering the unit testing layer of a microservices project. I designed, documented and implemented a solution based on the testing pyramid principles."
						/>
						<ExperienceCard
							company="Everis"
							position="Software Developer"
							description="As part of a team based in Seville I participated in a project with TDD, CQRS and BDD. In this project had the chance of training my mates on best practices with unit tests."
						/>
						<ExperienceCard
							company="Everis"
							position="Software Developer II"
							description="As part of a team based in Seville working in an international project - IADB - I had the chance of delivering quality upgrades and quality focus solutions to an already built microservices project."
						/>
					</StyledExperienceCardRow>
					<StyledExperienceCardRow>
						<ExperienceCard
							company="Pulse Market"
							position="Senior Developer"
							description="As part of a multicultural team, in this startup I had the chance to take experience working in english full time. It was a great experience working with NestJS and CosmosDB database."
						/>
						<ExperienceCard
							company="AON"
							position="Senior Backend Developer"
							description="As a senior backend developer I worked with DynamoDB, an API and some lambdas in .NET in a project to analyse documents using AWS services."
						/>
						<ExperienceCard
							startExpanded
							company="AON"
							position="Senior Fullstack Developer"
							description="As senior fullstack developer I worked delivering React + .NET solutions to satisfy the company needs. This gave me the chance of working with Gitlab CI, GraphQL - using Apollo in Frontend and HotChocolate in backend, and other technologies."
						/>
					</StyledExperienceCardRow>
				</StyledExperienceCardGrid>
				{width && width > 2040 ? (
					<StyledAssetContainer>
						<img src={profile} alt="asset-ilustration" />
					</StyledAssetContainer>
				) : null}
			</StyledExperienceContent>
		</Layout>
	);
};

export { Experience };
