import styled from 'styled-components';
import { open } from '../../utils';
import { publish } from '../../assets';
import { useWindowSize } from '../../hooks/useWindowSize';

const StyledPosts = styled.span`
	width: 75%;

	display: flex;
	flex-direction: column;

	min-height: 66vh;
`;

const StyledPostsBody = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 50px;
`;

const StyledAssetContainer = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	min-height: 40vh;

	@media (min-width: 1360px) {
		opacity: 0;
	}

	@media (min-width: 1540px) {
		opacity: 0.3;
	}

	@media (min-width: 1720px) {
		opacity: 0.6;
	}

	@media (min-width: 1800px) {
		opacity: 1;
	}

	transition: opacity 1s linear;

	img {
		transform: scale(0.6);
		border-bottom: white solid 2px;
		padding: 0 100px;
	}

	background: radial-gradient(
		var(--purple),
		var(--purple-background),
		transparent 65%
	);

	animation: shutDown 1s linear;

	:hover {
		animation: enlight 1s linear;
		background: radial-gradient(
			var(--purple),
			var(--purple-background),
			transparent 80%
		);
	}

	border-radius: 20px;
`;

const StyledPostGrid = styled.span`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const StyledPostRow = styled.span`
	display: flex;
	flex-direction: row;
	gap: 40px;

	@media (max-width: 1080px) {
		flex-direction: column;
	}
`;

const StyledPostCard = styled.span<{ incomplete?: boolean }>`
	width: 300px;

	border: ${({ incomplete }) =>
		incomplete
			? 'rgba(255, 255, 255, 0.2) dashed 2px'
			: 'rgba(255, 255, 255, 0.4) solid 2px'};
	border-radius: 5px;

	color: ${({ incomplete }) =>
		incomplete ? 'rgba(255, 255, 255, 0.2)' : 'white'};

	${({ incomplete }) =>
		incomplete
			? ''
			: `:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.2);
        }`};

	h4 {
		margin: 10px;
		width: 80%;
	}

	@media (max-width: 810px) {
		width: 100%;
	}
`;

const StyledTipsRow = styled.span`
	display: flex;
	align-self: center;
	justify-content: center;

	background-color: var(--purple-background);

	border-radius: 10px;
	width: 100%;
	margin-top: 50px;
	padding: 25px 0;

	p {
		text-align: center;
		width: 66%;
		font-style: italic;
	}

	strong {
		font-weight: lighter;
	}
`;

const PostCard: React.FC<any> = ({ title, link }) => (
	<StyledPostCard
		className={`noselect ${!link ? 'box-shadow' : ''}`}
		incomplete={!link}
		onClick={() => open(link)}
	>
		<h4>{title}</h4>
	</StyledPostCard>
);

const Posts: React.FC<any> = () => {
	const { width } = useWindowSize();

	return (
		<StyledPosts className="max-width">
			<h1>Posts</h1>
			<StyledPostsBody>
				<StyledPostGrid>
					<StyledPostRow>
						<PostCard
							title="Test-Driven Development & the 4-step-rule"
							link="https://medium.com/@currosoriaf/test-driven-development-the-4-step-rule-77a15312f9a1"
						/>
						<PostCard
							title="BDD — How, Who & When"
							link="https://currosoriaf.medium.com/bdd-how-who-when-16c00124967d"
						/>
						<PostCard
							title="Exception Handling"
							link="https://currosoriaf.medium.com/exception-handling-85527ac2dfca"
						/>
					</StyledPostRow>
					<StyledPostRow>
						<PostCard
							title="How to: React portfolio deployed into AWS — Part 1"
							link="https://currosoriaf.medium.com/how-to-react-portfolio-deployed-into-aws-part-1-92ab8bc37b44"
						/>
						<PostCard
							title="How to: React portfolio deployed into AWS — Part 2"
							link="https://currosoriaf.medium.com/how-to-react-portfolio-deployed-into-aws-part-2-169e31992826"
						/>
						<PostCard
							title="How to: React portfolio deployed into AWS — Part 3"
							link="https://currosoriaf.medium.com/how-to-react-portfolio-deployed-into-aws-part-3-ac9d64264168"
						/>
					</StyledPostRow>
					<StyledPostRow>
						<PostCard
							title="How to: React portfolio deployed into AWS — Part 4"
							link="https://currosoriaf.medium.com/how-to-react-portfolio-deployed-into-aws-part-4-778d8aa7c454"
						/>
						<PostCard
							title="How to: React portfolio deployed into AWS — Part 5"
							link="https://currosoriaf.medium.com/how-to-react-portfolio-deployed-into-aws-part-5-74a890eb3d9a"
						/>
						<PostCard title="How to: React portfolio deployed into AWS — Part 6" />
					</StyledPostRow>
					{width && width >= 1080 ? (
						<StyledTipsRow className="box-shadow">
							<p>
								“In almost all cases, I’m opposed to setting
								aside time for refactoring. In my view
								refactoring is not an activity you set aside
								time to do. Refactoring is something you do all
								the time in little bursts.”
								<br />
								<br />
								<strong>
									― Martin Fowler, Refactoring: Improving the
									Design of Existing Code
								</strong>
							</p>
						</StyledTipsRow>
					) : null}
				</StyledPostGrid>
				{width && width > 1360 ? (
					<StyledAssetContainer>
						<img src={publish} alt="asset-ilustration" />
					</StyledAssetContainer>
				) : null}
			</StyledPostsBody>
		</StyledPosts>
	);
};

export { Posts };
