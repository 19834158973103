import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import github from './icons/github.png';
import gitlab from './icons/gitlab.png';
import linkedin from './icons/linkedin.png';
import { open } from '../../utils';

const StyledMenu = styled.span`
	width: calc(100vw - 100px);
	height: 2em;
	position: fixed;
	top: 0;
	align-self: center;
	display: flex;
	justify-content: flex-end;
	padding: 1em 0em;
`;

const StyledButton = styled.button`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1em 1em;
	gap: 0.5em;
	border: var(--white) solid 1px;
	border-radius: 0.5em;
	background-color: rgba(0, 0, 0, 0);
	color: var(--white);

	transition: transform 1s ease, background-color 2s ease;

	:hover {
		background-color: var(--grey);
		transform: scale(1.05);
	}

	:focus {
		border: var(--white) solid 2px;
	}
`;

const StyledRRSSMenu = styled.span`
	position: fixed;
	left: 5%;
	top: 42vh;
	display: flex;
	flex-direction: column;
	gap: 1em;
	padding: 1.8em 1em;

	border: var(--purple) dotted 2px;
	border-radius: 10em;
	background: radial-gradient(
		var(--purple-dark-background),
		var(--purple-background) 65%,
		var(--purple-dark)
	);

	opacity: 0.6;

	:hover {
		opacity: 1;
	}

	transition: opacity 0.5s linear;

	@media screen and (max-width: 920px) {
		visibility: hidden;
	}
`;

const StyledItem = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 20000px;
	object-fit: cover;
	// transition: border 1s linear;

	background: radial-gradient(
		var(--purple) 60%,
		var(--purple-background) 85%,
		transparent
	);

	:hover {
		cursor: pointer;
	}
`;

export const Contact = () => {
	return (
		<StyledMenu>
			<StyledButton className="noselect" role="button">
				<FontAwesomeIcon
					icon={faComment}
					size="2x"
					color="var(--white)"
				/>
				<p>Contact</p>
			</StyledButton>
		</StyledMenu>
	);
};

export const RRSS = () => {
	return (
		<StyledRRSSMenu className="box-shadow">
			<StyledItem
				onClick={() => open('https://github.com/elreyrata')}
				role="button"
				src={github}
				alt="github icon"
			/>
			<StyledItem
				onClick={() => open('https://gitlab.com/elreyrata')}
				role="button"
				src={gitlab}
				alt="gitlab icon"
			/>
			<StyledItem
				onClick={() =>
					open(
						'https://www.linkedin.com/in/juan-francisco-soria-ferrer-576468168/'
					)
				}
				role="button"
				src={linkedin}
				alt="linkedin icon"
			/>
		</StyledRRSSMenu>
	);
};
