import styled from 'styled-components';

const StyledImage = styled.img<{ size?: number }>`
	width: 8em;
	height: 8em;
	border-radius: 12em;

	margin: -75px 0;
	// margin-left: -200px;
	border: var(--purple-background) 12px solid;

	transform: scale(${({ size }) => `${size}`});

	transition: border 1s linear, transform 1s linear;

	object-fit: cover;

	:hover {
		border: var(--purple-dark-background) 12px solid;
		transform: scale(1.05);
	}
`;

interface ImageProps {
	profilePic: any;
	size?: number;
}

const Image: React.FC<ImageProps> = ({ profilePic, ...rest }) => (
	<StyledImage {...rest} src={profilePic} alt="It's me. Hello!" />
);

export { Image };
